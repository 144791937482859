import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.ecApi = <EagleModuleApiInterface>{
    paymentTypes: () => api.request({
      url: `/api/ec/payment-types`,
      type: 'get',
    }),
    deliveryTypes: () => api.request({
      url: `/api/ec/delivery-types`,
      type: 'get',
    }),
  }

  api.collection.paymentApi = <EagleModuleApiInterface>{
    updateStatus: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/payment/${id}/status`,
      type: 'put',
      params,
    }),
  }

  api.collection.deliveryApi = <EagleModuleApiInterface>{
    createThirdPartyOrder: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/delivery/${id}/third-party-order`,
      type: 'post',
      params,
    }),
    updateStatus: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/delivery/${id}/status`,
      type: 'put',
      params,
    }),
  }

  api.collection.orderApi = <EagleModuleApiInterface>{
    query: (params: AnyObject) => api.request({
      url: `/api/admin/order/query`,
      type: 'post',
      params,
    }),
    read: (id: string) => api.request({
      url: `/api/admin/order/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/order/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/order`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/order/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/order/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/order/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/admin/order/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/order/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
    invalid: (id: string, params = {}) => api.request({
      url: `/api/admin/order/${id}/invalid`,
      type: 'post',
      params,
    }),
    cancelRequestApply: (id: string) => api.request({
      url: `/api/admin/order/${id}/apply-cancel-request`,
      type: 'post',
    }),
    cancelRequestReject: (id: string) => api.request({
      url: `/api/admin/order/${id}/reject-cancel-request`,
      type: 'post',
    }),
    returnRequestApply: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/order/${id}/apply-return-request`,
      type: 'post',
      params,
    }),
    returnRequestReject: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/order/${id}/reject-return-request`,
      type: 'post',
      params,
    }),
  }
}
